import shave from "shave";

const { getElementBySelector } = await import("@/util/elements");

const DATA_CONTAINER = "data-teaser";
const DATA_VIEWPORT = "data-teaser-viewport";
const DATA_CONTENT = "data-teaser-content";

export class Teasers {
    private static instance: Teasers;

    public static getInstance(): Teasers {
        if (!Teasers.instance) {
            Teasers.instance = new Teasers();
        }

        return Teasers.instance;
    }

    private constructor() {
        const teasers = document.querySelectorAll(`[${DATA_CONTAINER}]`);
        for (let i = 0; i < teasers.length; i++) {
            const teaser = teasers.item(i);

            if (!teaser) {
                return;
            }

            const viewport = getElementBySelector(`[${DATA_VIEWPORT}]`, teaser);
            const content = getElementBySelector(`[${DATA_CONTENT}]`, viewport);

            const resizeObserver = new ResizeObserver(() => {
                shave(content, viewport.clientHeight, { character: "..." });
            });

            resizeObserver.observe(teaser);
        }
    }
}
